<template>
  <div class="home">
    <div class="share-top">
         <van-search
            shape="round"
            background="none"
            @search="searchUpdate"
					   v-model="data.productName"
					   clearable
					   placeholder="请输入名称" />
        <!-- <div class="fa">
                <div class="fa-div">
                    <img style="width:1.3rem;" src="@/assets/img/home/icon_search.png"/>
                    <div class="fa-sp"></div> -->
                    <!-- <div @click="clickSearchTitle()" class="fa-div-label">请输入要查询的内容</div> -->
                    <!-- <div @click="clickSearchTitle()" class="fa-div-label"> -->
                    <!-- <van-field
                    v-model="data.searchTxt"
                    clearable
                    placeholder="请输入要查询的内容"
                    /> -->
                   
                    <!-- </div>  -->
                <!-- </div>
        </div> -->
                <div class="dt">
                    <div class="dt1" @click="changeType('01')">
                        <div :class="'dt2'+ (data.subType=='01'?' act':'')">进件开单</div>
                        <div :class="'dt3'+ (data.subType=='01'?' act':'')"></div>
                    </div>
                    <div class="dt4">
                    </div>
                    <div class="dt1" @click="changeType('02')">
                        <div :class="'dt2'+ (data.subType=='02'?' act':'')">招募团队</div>
                        <div :class="'dt3'+ (data.subType=='02'?' act':'')"></div>
                    </div>
                </div>
        
        <div class="dt" style="margin-top:1rem;">
            <div :class="'dt6 '+ (data.shareType=='01'?'sel':'')" @click="changeShareType('01')">
                &gt; 海报产品 &lt;
            </div>
            <div :class="'dt6 '+ (data.shareType=='02'?'sel':'')+' ml'" @click="changeShareType('02')">
                &gt; 日常海报 &lt;
            </div>
            <div :class="'dt6 '+ (data.shareType=='03'?'sel':'')+' ml'" @click="changeShareType('03')">
                &gt; 朋友圈 &lt;
            </div>
        </div>
    </div>
    <!----------产品中心开始------------->
    

    <ShareProduct ref="shareproduct" :subType="data.subType" @clickShare="clickShare" v-if="data.shareType=='01'"></ShareProduct>
    <SharePyq ref="sharepyq" :subType="data.subType" @clickShare="clickShare" v-if="data.shareType=='03'"></SharePyq>
    <ShareDay ref="shareday" :subType="data.subType" @clickShare="clickShare" v-if="data.shareType=='02'"></ShareDay>
    

<!-- 
     <div class="service">
      <div class="service-title2">客云云科技 · 让经纪人生活更美好</div>
      <div class="service-title3">移动办公 合规平台 专业服务</div>
    </div> -->

    <!----------菜单开始------------->
    <!-- <Menu :emodel="menuIndex"></Menu> -->
    <!----------菜单结束------------->

    <!-- <div style="height:100px;"></div> -->
  </div>

  <div v-if="data.shareShow" class="bback">
	</div>

  <div v-if="data.shareShow" class="pop-share">
		<div class="pop-close" @click="data.shareShow=false">×</div>
		<div class="pop-con">
      <img :src="data.image"/>
		</div>
		<div class="pop-btn">
			<div style="margin:1rem;margin-top:2rem;display: flex;">
                <van-button @click="data.shareShow=false" style="border:1px solid #C90000;background-color: #fff;color:#C90000;flex:1" size="large" round type="danger">返回</van-button>
                <!-- <van-button @click="saveShop()" style="margin-left:1rem;background-color:#C90000;flex:1" size="large" round type="danger">保存海报</van-button> -->

            </div>
		</div>
	</div>
</template>

<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../../common/http.js';
import {isNull,showMessage} from '../../common/utils.js'
import ShareProduct from './ShareProduct.vue';
import SharePyq from './SharePyq.vue';
import ShareDay from './ShareDay.vue';

const shareproduct=ref(null)
const sharepyq=ref(null)
const shareday=ref(null)

let route=useRoute()
let router=useRouter()
let menuIndex={index:1}

let data=reactive({
  shareShow:false,
  searchTxt:"",
  subType:'01',
  shareType:"01",
  typeList:[],
  productList:[],
  model:{},
  image:"",
  productName:""
})



let model=reactive({
    phone:"",
    yzm:""
})

let search=reactive({
  orderType:"",
  typeType:"10",
  typeCode:""
})

function clickMatch(){
  router.push({ path: '/match' })
}

function clickFun(index){
  if(index==1){
    router.push({ path: '/productlist' })
  }else if(index==2){
    clickMatch();
  }else if(index==3){
    router.push({ path: '/shop' })
  }
}

function clickSearchTitle(){
  if(data.searchType=="01"){
    //查方案
    clickMatch();
  }else if(data.searchType=="02"){
    //查产品 
    router.push({ path: '/productlist' })
  }else if(data.searchType=="03"){
    //查进度
  }
}

function clickShare(item){
  var spro={}
  if(item.type=="product"){
    spro={"shareType":"01","pickDesc":item.item};
  }else if(item.product=="day"){
    spro={"pickImg":item.img_url,"shareType":"02"};
  }else if(item.product=="pyq"){
    spro={"pickImg":item.img_url,"shareType":"03"};
  }
  api.post("/web/pick/code",spro).then((res)=>{
        if(res.errcode=="0"){
          data.image=res.image;
        }else{
          showMessage(res.errmsg);
        }
  })
  data.shareShow=true;
}

const tcomp = ref(null)

function changeType(type){
  data.subType=type;
  if(shareproduct.value){
  shareproduct.value.searchByParams(type);
  }
  if(sharepyq.value){
  sharepyq.value.searchByParams(type);
  }
  if(shareday.value){
  shareday.value.searchByParams(type);
  }
}

function changeShareType(type){
  data.shareType=type;
}

function searchUpdate(value){
  if(data.shareType=="01"){
    tcomp.value.parentSearchProduct(data.productName);
  }
  
}

</script>
<style scoped lang="scss">
.share-top{
    background: url("../../assets/img/home/home_top.png");
    padding:1rem;
}
.match-product{
    margin-left:0px;
    margin-right:0px;
    padding: 1rem;
    background: #eff1f2;
    .match-product-item{
        padding:1rem;
        background: #fff;
        margin-top:1rem;
        border-radius: 1rem;

        .item-news{
            border: 30px solid #c90100;
            height: 0;
            width: 0;
            border-right-color: transparent;
            border-bottom-color: transparent;
            position: absolute;
            margin-left: -1rem;
            margin-top: -1rem;
            border-top-left-radius: 1rem;
            .item-news-title{
                margin-left: -1.3rem;
                margin-top: -1.3rem;
                color: #fff;
            }
        }

        .item-hr{
            height: 1px;
            background: #f3f3f3;
            margin-top:0.3rem;
            margin-bottom:0.3rem;
        }
        .item-label{
            margin-top:1rem;
            margin-left:2rem;
            margin-right:2rem;
            font-size:0.9rem;
            .item-label-div{
                margin-bottom:0.5rem;
                display:flex;
                img{
                    width:1.2rem;
                    height:1.2rem;
                }
                div{
                    margin-left:0.4rem;
                }
            }
        }
        .item-top{
            display:flex;
            .item-top-img{
                width:4rem;
                height:4rem;
            }
            .item-top-desc{
                margin-left:1rem;
                flex:1;
                .item-back{
                    margin-top:0.5rem;
                    font-size:0.9rem;
                    width:100%;
                    .item-back-desc{
                        display: inline-block;
                        color:#3D6FFF;
                        border:1px solid #3D6FFF;
                        padding-left:0.3rem;
                        padding-right:0.3rem;
                        margin-right:0.5rem;
                        border-radius:0.3rem;
                    }
                }
                .item-prop{
                    color:#949999;
                    font-size:0.9rem;
                    width:100%;
                    .item-prop-desc{
                        display: inline-block;
                        margin-right:1rem;
                        margin-top:0.5rem;
                        .red{
                            margin-left:0.2rem;
                            color:#C90000;
                        }
                        .black{
                            margin-left:0.2rem;
                            color:#0A1E3C;
                        }
                    }
                }
                .item-name{
                    display:flex;
                    .item-name-name{
                        font-weight:500;
                    }
                    .item-name-desc{
                        margin-left:auto;
                        font-size:0.8rem;
                        margin-left:auto;
                        color:#3D6FFF;
                    }
                }
                .item-warn{
                    background: #fcefe5;
                    color: #d21616;
                    padding-left: 0.3rem;
                    padding-right: 0.3rem;
                    border-radius: 0.2rem;
                    display: inline;
                }
            }
        }
    }
}

.con-title{
    display: flex;
    margin:1rem;
    margin-top: 2rem;
    .con-title-bg-img{
        margin-top: -0.5rem;
        margin-left:1rem;
        flex: 1;
        .con-title-bg-img-img{
            margin-left: auto;
            text-align: right;
            img{
                width: 1.2rem;
            }
        }
        
        .con-title-bg{
            height:0.5rem;
            background: #e9ebeb;
            border-radius: 0.5rem;
            border-top-right-radius: 0;
        }
        
    }
    .con-title-detail{
        font-weight: 500;
    }
    .con-title-detail-sub{
        color: #949999;
        margin-top: 0.2rem;
        margin-left: 1rem;
        font-size: 0.8rem;
    }
    .con-title-desc{
        margin-left: auto;
        color:#3D6FFF;
        font-size:0.8rem;
    }
}


.dt{
    margin-top:0.5rem;
    display:flex;
    color: #fff;
    .dt6{
        border:1px solid #fff;
        border-radius: 1rem;
        padding:0.3rem;
        padding-left:0.5rem;
        padding-right:0.5rem;
        flex:1;
        text-align: center;
        &.sel{
            background: #fff;
            color:#3D6FFF;
        }
        &.ml{
            margin-left:1rem;
        }
    }
    .dt4{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        .sp{
            margin-top: 0.5rem;
            padding-top:0.3rem;
            padding-bottom:0.3rem;
            width:1px;
            height:0.3rem;
            background: #fff;
        }
    }
    .dt1{
        text-align:center;
        font-size:1rem;
    }
    .dt2{
        padding-top:0.3rem;
        padding-bottom:0.3rem;
        &.act{
            font-weight:bold;
            font-size:1.1rem;
            color:#fff;
        }
    }
    .dt3{
        &.act{
            height:0.2rem;
            background:#fff;
            margin-left:1rem;
            margin-right:1rem;
            border-radius:0.15rem;
        }
    }
}

.banner{
  width:100%;
  height:auto;
}



.divlight{
    position: absolute;
    display: flex;
    left: 1rem;
    right: 1rem;
    top: 1rem;
    .light{
        width: 3rem;
        height: 2.8rem;
    }
    .light_desc{
        color: #fff;
        margin-left: 1rem;
        .name{
            font-size: 1.2rem;
        }
        .desc{
            font-size: 0.8rem;
        }
    }
    .light_kf{
      margin-left:auto;
      margin-top:0.5rem;
      img{
        width: 7rem;
      }
    }
}

.app{
    .ml{
        margin-left:0.5rem;
    }
    .app-con{
        display: flex;
        margin-left:1rem;
        margin-right:1rem;
        
        .app-item{
            text-align: center;
            flex:1;
            border-radius: 1rem;
            height: 5rem;
            align-items:center;
            img{
                width:4rem;
                flex:1;
            }
            .app-title{
                text-align: center;
                font-size: 0.8rem;
            }
        }
    }
  
}

.service{
  .ml{
    margin-left:0.5rem;
  }
  

  .service-con{
    display: flex;
    margin-left:1rem;
    margin-right:1rem;
    
    .service-item{
     
      flex:1;
      
      border-radius: 1rem;
      display: flex;
      height: 5rem;
      align-items:center;
      img{
        margin-left:1rem;
        width: 2rem;
        height: 2rem;
      }
      .service-item-title{
        margin-left: 0.5rem;
        .title1{
          font-size: 0.9rem;
          font-weight: bold;
        }
        .title2{
          font-size: 0.6rem;
          color: grey;
        }
      }
      // background: url(@/assets/img/index/service_company.png);
    }
  }
  .service-title{
    text-align: center;
    color: #0071BC;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .service-title2{
    text-align: center;
    color: #848E9D;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 4rem;
  }

  .service-title3{
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}

.customer{
  .customer-con{
    margin:1rem;
    margin-left:2rem;
    margin-right:2rem;
    .customer-con-div{
      height:1.8rem;
      padding: 0.5rem;
      display: flex;
      justify-items: center;
      justify-content: center;
      line-height:1.8rem;
      color:#DCCCEC;
      
      .customer-con-div-name{
        display: flex;
        justify-content: center;
        justify-items: center;
        flex: 2;
        img{
          height: 1.4rem;
          margin-top:0.2rem;
        }
        div{
          vertical-align: middle;
          margin-left: 0.5rem;
        }
      }
      .customer-con-div-phone{
        justify-items: center;
        justify-content: center;
        display: flex;
        flex: 3;
      }
      .customer-con-div-time{
        text-align: center;
        vertical-align: middle;
        flex: 2;
      }

      &.on{
        color:#5000A0;
        background: #DCCCEC;
        border-radius: 1.3rem;
        .lineh{
          margin-top: 0.4rem;
          width:1px;
          height: 1rem;
          background: #5000A0;
        }
      }
    }
  }
  .customer-title-sub{
    text-align: center;
    color: #5000A0;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 2rem;
  }
  .customer-title{
    margin-top:2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .customer-label{
      color: #5000A0;
      line-height: 2rem;
      align-items: center;
      font-weight: 500;
      margin-left: 1rem;
      font-size: 1.1rem;
      a{
        // background: blue;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        color: #fff;
        border-radius: 0.3rem;
        font-size:1.3rem;
        background: -webkit-linear-gradient(left top, #5000A0 , #0071BC); /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(bottom right, #5000A0, #0071BC); /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(bottom right, #5000A0, #0071BC); /* Firefox 3.6 - 15 */
        background: linear-gradient(to bottom right, #5000A0 , #0071BC); /* 标准的语法 */
      }
    }
  }
}

.fa-title{
  margin:auto;
  text-align: center;
  font-size: 1.2rem;
  color: #225fb1;
}
.fa{
  .fa-btn{
    
  }
  .mt{
    margin-top:0.5rem;
  }
  .fa-div{
    // background: #fff;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items:center;
    .fa-sp{
        width:1px;
        background:#fff;
        height: 1rem;
        margin-left: 1rem;
    }
    .fa-div-label{
      margin:0.8rem;
      color:#fff;
    }
    img{
      width: 1rem;
      height: 1.3rem;
      vertical-align: middle;
    }
  }

  .fa-div-yzm{
    display: flex;
  }
  .fa-chk{
    img{
      width:1rem;
    }
  }

  .fa-div-radio{
    margin-top:1rem;
    padding-left:1rem;
    display: flex;
    align-items: center;
    .fa-radio{
      width:0.8rem;
      height:0.8rem;
      border: 1px solid #ddd;
      border-radius: 50%;
      .fa-sel{
        width:0.6rem;
        height:0.6rem;
        margin:0.1rem;
        border-radius: 50%;
        background: #4372f6;
      }
    }
    .fa-label{
      font-size: 0.9rem;
      margin-left: 0.5rem;
      color:#0A1E3C;
    }
    .fa-label-xy{
      font-size: 0.7rem;
      margin-left: 0.5rem;
      color:#afb4bd;
      a{
        color:#3D6FFF;
      }
    }

     
  }

  .hr{
    background: #ddd;
    height:1px;
  }

  .van-field__control{
    margin-left: 0.5rem;
  }
}


.pop-share{
  height: 90%;
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 99999;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  .pop-con{
    margin-left: 1rem;
    margin-right: 1rem;

    .mt{
      margin-top:0.5rem;
    }
    img{
      width:100%;
    }
  }
  
  .pop-close{
    padding-left: 0.5rem;
    font-size:1.8rem;
  }
  .pop-wtitle{
    margin-left: 3rem;
    margin-right: 3rem;
    .pop-title{
      font-size: 1rem;
      font-weight: bold;
    }
    .pop-sp{
      margin-top:0.5rem;
      margin-bottom:0.5rem;
      height: 1px;
      background: #ebebeb;
    }
    .pop-title-sub{
      color:#949999;
      margin-bottom:0rem;
      font-size: 0.9rem;
    }
    
    .pop-title-label{
      margin-bottom:1rem;
      .pop-title-label-city{
        border: 1px solid #ebebeb;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 1rem;
        font-size: 0.8rem;
        margin-right: 0.8rem;
        float: left;
        margin-top: 0.5rem;
      }
      .clear{
        clear: both;
      }
    }
  }
  .pop-sph{
    height: 0.3rem;
    background: #ebebeb;
  }
  .pop-picker{
    
  }
  .pop-btn{
    margin:1rem;
  }
}
</style>
