<template>
<div class="pcon">
	<div class="pconitem" v-for="item in data.pickList"
		 :key="item.pick_code">
		<div class="pconitemtop">
			<div class="pcon1">
				<img src="../../assets/img/me/icon_head.png" />
			</div>
			<div class="pcon2">
				<div class="pconname">客云云</div>
				<div class="pcondesc">{{item.pick_desc}}</div>
				<div class="pconimg">
					<img @click="clickImg2(sitem)" v-for="(sitem) in item.pickImgList"
                        :key="sitem.pick_img_code" :src="sitem.img_url_org"/>
				</div>
				<div class="pcon3">
					<div class="pcontime">更新于2021.02.01</div>
					<div class="pconcopy" @click="copy(item)">复制文案</div>
				</div>
			</div>
		</div>

	</div>
</div>
</template>

<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose,defineEmits} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../../common/http.js';
import {isNull,showMessage} from '../../common/utils.js'
import useClipboard from 'vue-clipboard3'

const emit=defineEmits(['clickShare']);

function clickImg2(item){
  emit('clickShare',{type:"pyq",item:item});
}

const props=defineProps({
    subType:""
})

let data=reactive({
    pickList:[]
})
const { toClipboard } = useClipboard()
function copy(item){
    try {
        toClipboard(item.pick_desc)
        showMessage("文案已复制");
      } catch (e) {
        console.error(e)
      }
}
let search=reactive({
    "pickType":"63"
})

search.subType=props.subType;
function searchPick(){
  api.post("/web/pick/picklist",search).then((res)=>{
        if(res.errcode=="0"){
          data.pickList=res.rows;
        }else{
          showMessage(res.errmsg);
        }
  })
}

function searchByParams(subType){
  search.subType=subType;
  searchPick();
}

defineExpose({
  searchByParams
})

searchPick();
</script>

<style scoped lang="scss">
.pcon{
            background:#fff;
            .pconitem{
                padding:1rem;
                
                .pconitemtop{
                    display:flex;
                    .pcon1{
                        img{
                            width:3rem;
                            border-radius:0.5rem;
                            background: #ebebeb;
                            border-radius: 50%;
                        }
                    }
                    .pcon2{
                        margin-left:1rem;
                        width: 100%;
                        .pconname{
                            font-size:1.2rem;
                            font-weight:bold;
                            color:#536c95;
                        }
                        .pcondesc{
                            font-weight:480;
                            color:#333;
                            font-size:1rem;
                        }
                        .pconimg{
                            margin-top:0.3rem;
                            img{
                                width:5rem;
                                margin-right:0.3rem;
                                height:5rem;
                            }
                        }
                    }
                }
               
                .pcon3{
                    display:flex;
                    margin-top:1rem;
                    .pcontime{
                        color:#999;
                        font-weight:480;
                    }
                    .pconcopy{
                        margin-left:auto;
                        padding:0.2rem;
                        padding-left:1rem;
                        padding-right:1rem;
                        background: #feebea;
                        border-radius: 1rem;
                        color: #fd2b23;
                    }
                }
                
            }
        }
</style>