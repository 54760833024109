<template>
	<div class="home">
		<div class="dtshop">
            <div  class="dt1" @click="changeType(0)">
                <div class="dt2 act">贷款产品</div>
                <div class="dt3 act"></div>
            </div>
            <div class="dt4"></div>
            <div class="dt1" @click="changeType(1)">
                <div class="dt2">信用卡</div>
                <div class="dt3"></div>
            </div>
            <div class="dt4"></div>
            <div class="dt1" @click="changeType(2)">
                <div class="dt2">保险</div>
                <div class="dt3"></div>
            </div>
            
        </div>


		<!----------热门产品开始------------->

		<div class="match-center-type">
			<van-row style="margin-top:1rem;">
				<van-col span="6" class="match-center-type-type2">
					<div @click="clickSearch('typeCode','')" :class="'btn'+ (search.typeCode==''?' sel':'')">全部贷款</div>
				</van-col>
				<van-col span="18">
					<div class="match-center-type-type1">
						<div class="match-center-type-type2">
							<div @click="clickSearch('typeCode',item.type_code)" :class="'btn'+(search.typeCode==item.type_code?' sel':'')" v-for="item in data.typeList" :key="item.type_code">{{item.type_name}}</div>
						</div>
					</div>
				</van-col>
			</van-row>

			<van-row style="margin-top:1rem;">
				<van-col span="6" class="match-center-type-type2">
					<div @click="clickSearch('orderType','')" :class="'btn'+ (search.orderType==''?' sel':'')">综合排序</div>
				</van-col>
				<van-col span="18">
					<div class="match-center-type-type1">
						<div class="match-center-type-type2">
							<div @click="clickSearch('orderType','01')" :class="'btn'+ (search.orderType=='01'?' sel':'')">按返佣</div>
							<div @click="clickSearch('orderType','02')" :class="'btn'+ (search.orderType=='02'?' sel':'')">按利息</div>
							<div @click="clickSearch('orderType','03')" :class="'btn'+ (search.orderType=='03'?' sel':'')">按额度</div>
						</div>
					</div>
				</van-col>
			</van-row>
		</div>
		<!----------热门产品结束------------->

		<div style="background: #eff1f2;">
			<div class="dt" style="padding-top:0.5rem;">
				<div class="dt1" @click="changePop('city')">
					<div class="dt2">
						<div class="dt2-title">{{search.city==''?'所属地':search.city}}</div>
						<div class="dt2-sj-b">
							<div class="dt2-sj"></div>
						</div>
					</div>
				</div>
				<div class="dt4">
					<div class="sp"></div>
				</div>
				<div class="dt1" @click="changePop('tax')">
					<div class="dt2">
						<div class="dt2-title">纳税开票</div>
						<div class="dt2-sj-b">
							<div class="dt2-sj"></div>
						</div>
					</div>
				</div>
				<div class="dt4">
					<div class="sp"></div>
				</div>
				<div class="dt1" @click="changePop('people')">
					<div class="dt2">
						<div class="dt2-title">身份占股</div>
						<div class="dt2-sj-b">
							<div class="dt2-sj"></div>
						</div>
					</div>
				</div>
				<div class="dt4">
					<div class="sp"></div>
				</div>
				<div class="dt1" @click="changePop('age')">
					<div class="dt2">
						<div class="dt2-title">年龄</div>
						<div class="dt2-sj-b">
							<div class="dt2-sj"></div>
						</div>
					</div>
				</div>
				<div class="dt4">
					<div class="sp"></div>
				</div>
				<div class="dt1" @click="changePop('change')">
					<div class="dt2">
						<div class="dt2-title">变更</div>
						<div class="dt2-sj-b">
							<div class="dt2-sj"></div>
						</div>
					</div>
				</div>

			</div>

			<div class="match-product">
                <div class="match-product-dis" v-for="item in data.productList" :key="item.product_channel_code">
                    <div class="match-product-item">
                        <div v-if="item.flag_hot=='1'" class="item-news">
                            <div class="item-news-title">新</div>
                        </div>
                        <div class="item-top">
                            <img class="item-top-img" src="@/assets/img/match/icon_360.png" />
                            <div class="item-top-desc">
                                <div class="item-name">
                                    <div class="item-name-name">{{item.product_name}}-{{item.type_name}}</div>
                                    <!-- <div class="item-name-desc">立即进件 ></div> -->
                                </div>
                                <div class="item-hr"></div>
                                <div class="item-warn">最高{{item.money_max}}万</div>
                                <div class="item-prop">
                                    <div class="item-prop-desc">
                                        最高额度<a class="red">{{item.money_max}}万</a>
                                    </div>
                                    <div class="item-prop-desc">
                                        年化利率<a class="black">{{item.rate_min}}% - {{item.rate_max}}%</a>
                                    </div>
                                    <div class="item-prop-desc">
                                        贷款期限<a class="black">{{disLabel(item.times,"期")}}</a>
                                    </div>
                                </div>
                                <div class="item-back">
                                    <div class="item-back-desc" :key="sitem" v-for="sitem in JSON.parse(item.labels)">
                                        {{sitem}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div :class="'match-product-dis-top '+(data.chk[item.product_channel_code]?'sel':' ')" style="display:flex">
                        <div style="margin-left:auto;">
                            <van-checkbox v-model="data.chk[item.product_channel_code]" label-position="left">{{data.chk[item.product_channel_code]?'':'请选择产品生成推广海报'}}</van-checkbox>
                        </div>
                    </div> -->
                    <div @click="data.chk[item.product_channel_code]=!data.chk[item.product_channel_code]" :class="'match-product-dis-bot '+(data.chk[item.product_channel_code]?'sel':' ')" style="display:flex">
                      <div>
                        <img v-if="!data.chk[item.product_channel_code]" src="@/assets/img/home/share_add.png" />
                        <img v-if="data.chk[item.product_channel_code]" src="@/assets/img/home/share_sel.png" />
                      </div>
                    </div>
                </div>

			</div>

             <div class="bot">
                <div class="bot-btn">
                <van-button @click="back()" style="border:1px solid #C90000;background-color: #fff;color:#C90000;flex:1" size="large" round type="danger">取消</van-button>
                <van-button @click="clickShare()" style="margin-left:1rem;background-color:#C90000;flex:2" size="large" round type="danger">生成产品海报</van-button>
              </div>
            </div>

			<div class="service">
				<div class="service-title2">客云云科技 · 让经纪人生活更美好</div>
				<div class="service-title3">移动办公 合规平台 专业服务</div>
			</div>
      <div style="height:100px;"></div>
		</div>
		<!----------菜单开始------------->
		<!-- <Menu :emodel="menuIndex"></Menu> -->
		<!----------菜单结束------------->

		


	</div>

	<div v-if="data.cityShow||data.taxShow||data.peopleShow||data.ageShow||data.changeShow" class="bback">
	</div>
	<div v-if="data.cityShow" class="pop-city">
		<div class="pop-close" @click="data.cityShow=false">×</div>
		<div class="pop-wtitle">
			<div class="pop-title">准入城市</div>
			<div class="pop-sp"></div>
			<div class="pop-title-sub">准入城市</div>
			<div class="pop-title-label">
				<div @click="clickCity('广东','深圳')" class="pop-title-label-city">深圳</div>
				<div @click="clickCity('广东','广州')" class="pop-title-label-city">广州</div>
				<div @click="clickCity('北京','北京')" class="pop-title-label-city">北京</div>
				<div @click="clickCity('上海','上海')" class="pop-title-label-city">上海</div>
				<div @click="clickCity('天津','天津')" class="pop-title-label-city">天津</div>
				<div @click="clickCity('重庆','重庆')" class="pop-title-label-city">重庆</div>
				<div @click="clickCity('四川','成都')" class="pop-title-label-city">成都</div>
				<div @click="clickCity('福建','厦门')" class="pop-title-label-city">厦门</div>
				<div @click="clickCity('江苏','南京')" class="pop-title-label-city">南京</div>
				<div @click="clickCity('湖北','武汉')" class="pop-title-label-city">武汉</div>
				<div class="clear"></div>
			</div>
		</div>
		<div class="pop-sph"></div>
		<div class="pop-picker">
			<van-picker ref="cityPicker" @change="onCityChange" :show-toolbar="false" :columns="address" :columns-field-names="cityFieldName" />
		</div>
		<div class="pop-btn">
			<van-button @click="clickCity2()" class="mt fa-btn" style="background-color:#C90000;" size="large" round type="danger">确定</van-button>

		</div>
	</div>

  <!------------税务开始------------->
	<div v-if="data.taxShow" class="pop-city">
		<div class="pop-close" @click="data.taxShow=false">×</div>
		<div class="pop-con">
      <div class="pop-rel">
        <div class="field-div mt" @click="data.taxLevelShow=true">
          <img src="../../assets/img/scheme/icon_money.png" />
          <div class="field-sp"></div>
          <van-field
                readonly="true"
                v-model="search.taxLevel"
                clearable
                placeholder="纳税评级" />
          <div class="field-unit-sub">
            <div class="field-sp-sub"></div>
            <div class="dt2-sj-b">
              <div class="dt2-sj"></div>
            </div>
          </div>
        </div>
        <div v-if="data.taxLevelShow" class="pop-sel">
          <div @click="clickTax('taxLevel','A')" class="pop-sel-div bot">
            <img class="pop-sel-div-rad" v-if="search.taxLevel=='A'" src="@/assets/img/index/icon_rad_sel.png"/>
            <img class="pop-sel-div-rad" v-if="search.taxLevel!='A'" src="@/assets/img/index/icon_rad.png"/>
            <div class="pop-sel-div-lab">A</div>
          </div>
          <div @click="clickTax('taxLevel','B')" class="pop-sel-div bot">
            <img class="pop-sel-div-rad" v-if="search.taxLevel=='B'" src="@/assets/img/index/icon_rad_sel.png"/>
            <img class="pop-sel-div-rad" v-if="search.taxLevel!='B'" src="@/assets/img/index/icon_rad.png"/>
            <div class="pop-sel-div-lab">B</div>
          </div>
          <div @click="clickTax('taxLevel','C')" class="pop-sel-div bot">
            <img class="pop-sel-div-rad" v-if="search.taxLevel=='C'" src="@/assets/img/index/icon_rad_sel.png"/>
            <img class="pop-sel-div-rad" v-if="search.taxLevel!='C'" src="@/assets/img/index/icon_rad.png"/>
            <div class="pop-sel-div-lab">C</div>
          </div>
          <div @click="clickTax('taxLevel','D')" class="pop-sel-div bot">
            <img class="pop-sel-div-rad" v-if="search.taxLevel=='D'" src="@/assets/img/index/icon_rad_sel.png"/>
            <img class="pop-sel-div-rad" v-if="search.taxLevel!='D'" src="@/assets/img/index/icon_rad.png"/>
            <div class="pop-sel-div-lab">D</div>
          </div>
          <div @click="clickTax('taxLevel','M')" class="pop-sel-div">
            <img class="pop-sel-div-rad" v-if="search.taxLevel=='M'" src="@/assets/img/index/icon_rad_sel.png"/>
            <img class="pop-sel-div-rad" v-if="search.taxLevel!='M'" src="@/assets/img/index/icon_rad.png"/>
            <div class="pop-sel-div-lab">M</div>
          </div>
        </div>
      </div>

			<div class="field-div mt">
				<img src="../../assets/img/index/icon_phone3.png" />
				<div class="field-sp"></div>
				<van-field
						   v-model="search.taxMonth"
						   clearable
						   placeholder="纳税时长" />
				<div class="field-unit">
					月
				</div>

			</div>

			<div class="field-div mt">
				<img src="../../assets/img/index/icon_phone3.png" />
				<div class="field-sp"></div>
				<van-field
						   v-model="search.taxMoneyYear"
						   clearable
						   placeholder="纳税总额" />
				<div class="field-unit">
					万元
				</div>

			</div>


			<div class="field-div mt">
				<img src="../../assets/img/index/icon_phone3.png" />
				<div class="field-sp"></div>
				<van-field
						   v-model="search.billingMonth"
						   clearable
						   placeholder="开票时长" />
				<div class="field-unit">
					月
				</div>

			</div>


			<div class="field-div mt">
				<img src="../../assets/img/index/icon_phone3.png" />
				<div class="field-sp"></div>
				<van-field
						   v-model="search.billingMoneyYear"
						   clearable
						   placeholder="开票总额" />
				<div class="field-unit">
					万元
				</div>

			</div>


		</div>
		<div class="pop-btn">
			<van-button @click="clickTaxSure()" class="mt fa-btn" style="background-color:#C90000;" size="large" round type="danger">确定</van-button>

		</div>
	</div>
  <!------------税务结束------------->

  <!------------身份开始------------->
	<div v-if="data.peopleShow" class="pop-city">
		<div class="pop-close" @click="data.peopleShow=false">×</div>
		<div class="pop-con">
      <div class="pop-rel">
        <div class="field-div mt" @click="data.peopleTypeShow=true">
          <img src="../../assets/img/scheme/icon_sf.png" />
          <div class="field-sp"></div>
          <van-field
                readonly="true"
                v-model="search.peopleType"
                clearable
                placeholder="身份准入" />
          <div class="field-unit-sub">
            <div class="field-sp-sub"></div>
            <div class="dt2-sj-b">
              <div class="dt2-sj"></div>
            </div>
          </div>
        </div>
        <div v-if="data.peopleTypeShow" class="pop-sel">
          <div @click="clickTax('peopleType','法人')" class="pop-sel-div bot">
            <img class="pop-sel-div-rad" v-if="search.peopleType=='法人'" src="@/assets/img/index/icon_rad_sel.png"/>
            <img class="pop-sel-div-rad" v-if="search.peopleType!='法人'" src="@/assets/img/index/icon_rad.png"/>
            <div class="pop-sel-div-lab">法人</div>
          </div>
          <div @click="clickTax('peopleType','股东')" class="pop-sel-div bot">
            <img class="pop-sel-div-rad" v-if="search.peopleType=='股东'" src="@/assets/img/index/icon_rad_sel.png"/>
            <img class="pop-sel-div-rad" v-if="search.peopleType!='股东'" src="@/assets/img/index/icon_rad.png"/>
            <div class="pop-sel-div-lab">股东</div>
          </div>
          <div @click="clickTax('peopleType','法人兼股东')" class="pop-sel-div bot">
            <img class="pop-sel-div-rad" v-if="search.peopleType=='法人兼股东'" src="@/assets/img/index/icon_rad_sel.png"/>
            <img class="pop-sel-div-rad" v-if="search.peopleType!='法人兼股东'" src="@/assets/img/index/icon_rad.png"/>
            <div class="pop-sel-div-lab">法人兼股东</div>
          </div>
        </div>
      </div>

			<div class="field-div mt">
				<img src="../../assets/img/scheme/icon_pie.png" />
				<div class="field-sp"></div>
				<van-field
						   v-model="search.peopleProportion"
						   clearable
						   placeholder="授权比例" />
				<div class="field-unit">
					%
				</div>

			</div>
		</div>
		<div class="pop-btn">
			<van-button @click="clickPeopleSure()" class="mt fa-btn" style="background-color:#C90000;" size="large" round type="danger">确定</van-button>
		</div>
	</div>
  <!------------身份结束------------->


  <!------------年龄开始------------->
	<div v-if="data.ageShow" class="pop-city">
		<div class="pop-close" @click="data.ageShow=false">×</div>
		<div class="pop-con">
			<div class="field-div mt">
				<img src="../../assets/img/scheme/icon_age.png" />
				<div class="field-sp"></div>
				<van-field
						   v-model="search.age"
						   clearable
						   placeholder="年龄准入" />
				<div class="field-unit">
					周岁
				</div>

			</div>
		</div>
		<div class="pop-btn">
			<van-button @click="clickAgeSure()" class="mt fa-btn" style="background-color:#C90000;" size="large" round type="danger">确定</van-button>
		</div>
	</div>
  <!------------年龄结束------------->

  <!------------年龄开始------------->
	<div v-if="data.changeShow" class="pop-city">
		<div class="pop-close" @click="data.changeShow=false">×</div>
		<div class="pop-con">
      <div class="field-div mt">
				<img src="../../assets/img/scheme/icon_age.png" />
				<div class="field-sp"></div>
				<van-field
						   v-model="search.setUpMonths"
						   clearable
						   placeholder="企业注册时长" />
				<div class="field-unit">
					月
				</div>

			</div>
			<div class="field-div mt">
				<img src="../../assets/img/scheme/icon_age.png" />
				<div class="field-sp"></div>
				<van-field
						   v-model="search.legalPersonMonths"
						   clearable
						   placeholder="法人变更时长" />
				<div class="field-unit">
					月
				</div>

			</div>
		</div>
		<div class="pop-btn">
			<van-button @click="clickChangeSure()" class="mt fa-btn" style="background-color:#C90000;" size="large" round type="danger">确定</van-button>
		</div>
	</div>
  <!------------年龄结束------------->
</template>

<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose,defineEmits} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../../common/http.js';
import {isNull,showMessage,disLabel} from '../../common/utils.js'
import address from "../../common/addr.json";

let route=useRoute()
let router=useRouter()
let menuIndex={index:1}
const cityPicker = ref(null);

const props=defineProps({
    subType:""
})

const emit=defineEmits(['clickShare']);

let data=reactive({
  changeShow:false,
  ageShow:false,
  peopleShow:false,
  peopleTypeShow:false,
  taxShow:false,
  taxLevelShow:false,
  cityShow:false,
  searchTxt:"",
  searchType:'01',
  typeList:[],
  productList:[],
  chk:{}
})

function back(){
    router.go(-1);
}

function clickShare(){
  var plist=[];
    var keys = Object.keys(data.chk);
    for(var key in data.chk){
        if(data.chk[key]){
            plist.push(key);
        }
    }
  if(plist.length==0){
    showMessage("请选择产品");
    return;
  }
  emit('clickShare',{type:"product",item:JSON.stringify(plist)});
}

function productUpdate(){
  searchProduct();
}

function clickProduct(item){
  router.push({ path: '/productdetail',query:{"productCode":item.product_code,"productChannelCode":item.product_channel_code} })
}

function changePop(pre){
  data[pre+'Show']=true;
}

function searchByParams(subType){
  data.subType=subType;
}

let cityFieldName = {
      text: 'name',
      children: 'sub',
};



let model=reactive({
    phone:"",
    yzm:""
})

let search=reactive({
  orderType:"",
  typeType:"10",
  typeCode:"",
  province:'',
  city:'',
  taxLevel:''
})

function clickProductTable(){
  router.push({ path: '/producttable' })
}


function clickSearchTitle(){
  if(data.searchType=="01"){
    //查方案
  }else if(data.searchType=="02"){
    //查产品 
    
  }else if(data.searchType=="03"){
    //查进度
  }
}

function changeType(type){
  data.searchType=type;
}

function clickTax(key,value){
  if(search[key]==value){
    return;
  }
  search[key]=value;
  data.peopleTypeShow=false;
  data.taxLevelShow=false;
}


function clickAgeSure(){
  data.ageShow=false;
  searchProduct();
}

function clickPeopleSure(){
  data.peopleShow=false;
  searchProduct();
}

function clickTaxSure(){
  data.taxShow=false;
  searchProduct();
}

function clickChangeSure(){
  data.changeShow=false;
  searchProduct();
}

function clickSearch(key,value){
  if(search[key]==value){
    return;
  }
  search[key]=value;
  searchProduct();
}




function clickCity(province,city){
  search['province']=province;
  search['city']=city;
  data.cityShow=false;
  searchProduct();
}

function clickCity2(){
  if(cityPicker.value.getColumnValue(1).name=="全国"){
    search['province']='';
    search['city']='';
  }else{
    search['province']=cityPicker.value.getColumnValue(0).name;
    search['city']=cityPicker.value.getColumnValue(1).name;
  }
  searchProduct();
  data.cityShow=false;
}

function searchType(){
  api.post("/web/type/typelist",{typeType:"10"}).then((res)=>{
        console.log(JSON.stringify(res))
        if(res.errcode=="0"){
          data.typeList=res.rows;
        }else{
          showMessage(res.errmsg);
        }
  })
}

function searchProduct(){
  api.post("/web/product/productlist",search).then((res)=>{
        if(res.errcode=="0"){
          data.productList=res.rows;
        }else{
          showMessage(res.errmsg);
        }
  })
}

function parentSearchProduct(productName){
  search.productName=productName;
  searchProduct();
}

defineExpose({
  parentSearchProduct
})

searchType();
searchProduct();
</script>
<style scoped lang="scss">
.bot{
  position: fixed;
  bottom: 0px;
  left: 0rem;
  right: 0rem;
  max-width: 30rem;
  
  background: #fff;
  .bot-btn{
    display: flex;
    padding:1rem;
  }
}
.pop-city{
  height: 57%;
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 99999;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  .pop-con{
    margin-left: 1rem;
    margin-right: 1rem;

    .mt{
      margin-top:0.5rem;
    }
    .pop-rel{
      position: relative;
      .pop-sel{
        position: absolute;
        border: 1px solid #949999;
        border-radius: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: #fff;
        z-index: 999;
        right: 0px;
        left: 0px;
        .pop-sel-div{
          display: flex;
          height:2.5rem;
          justify-content: center;
          &.bot{
            border-bottom: 1px solid #949999;
          }
          .pop-sel-div-rad{
            width:1rem;
            height:1rem;
            margin-top: 0.75rem;
          }
          .pop-sel-div-lab{
            line-height:2.5rem;
            margin-left: 1rem;;
          }
        }
      }
    }
    .field-div{
      border: 1px solid #949999;
      border-radius: 2rem;
      padding-left: 1rem;
      display: flex;
      align-items:center;
      .field-sp{
        width:1px;
        background: #ececec;
        height: 1rem;
        margin-left:0.8rem;
      }
      .field-div-label{
        margin:0.8rem;
        color:#afb4bd;
      }
      .field-unit-sub{
        width: 8rem;
        text-align: center;
        height: 2.8rem;
        line-height: 2.8rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color:white;
        display: flex;
        .field-sp-sub{
          width:1px;
          background: #ececec;
          height: 1rem;
          margin-top:0.8rem;
        }
        .dt2-sj-b{
            margin-top: 1.3rem;
            width: 100%;
            text-align: center;
            justify-content: center;
            justify-items: center;
            display: flex;
        }
        .dt2-sj{
            width: 0px;
            height: 0px;
            border: 0.4rem solid transparent;
            border-top-color: #949999;
        }
      }
      .field-unit{
        background: #929898;
        width: 8rem;
        text-align: center;
        height: 2.8rem;
        line-height: 2.8rem;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color:white;
      }
      img{
        width: 1rem;
        height: 1.3rem;
        vertical-align: middle;
      }
    }
  }
  
  .pop-close{
    padding-left: 0.5rem;
    font-size:1.8rem;
  }
  .pop-wtitle{
    margin-left: 3rem;
    margin-right: 3rem;
    .pop-title{
      font-size: 1rem;
      font-weight: bold;
    }
    .pop-sp{
      margin-top:0.5rem;
      margin-bottom:0.5rem;
      height: 1px;
      background: #ebebeb;
    }
    .pop-title-sub{
      color:#949999;
      margin-bottom:0rem;
      font-size: 0.9rem;
    }
    
    .pop-title-label{
      margin-bottom:1rem;
      .pop-title-label-city{
        border: 1px solid #ebebeb;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        border-radius: 1rem;
        font-size: 0.8rem;
        margin-right: 0.8rem;
        float: left;
        margin-top: 0.5rem;
      }
      .clear{
        clear: both;
      }
    }
  }
  .pop-sph{
    height: 0.3rem;
    background: #ebebeb;
  }
  .pop-picker{
    
  }
  .pop-btn{
    margin:1rem;
  }
}
.pro_search{
    margin-left: 1rem;
    margin-right: 1rem; 
    z-index: 9999;
    position: relative;
    margin-top: -1rem;
    .pro_search_img{
        width:100%;
    }
    .pro_search_title{
        margin-top: -2.4rem;
        text-align: center;
        color: #fff;
        font-size: 1.1rem;
        margin-bottom:1rem;
    }
}
.match-product{
    margin-left:0px;
    margin-right:0px;
    padding: 1rem;
    background: #eff1f2;
    .match-product-dis{
        border:1px solid #ebebeb;
        border-radius: 1rem;
        margin-bottom:1rem;
        position: relative;
        .match-product-dis-top{
            background: rgba(255, 255, 255, 0.8);
            border-radius: 1rem;
            padding:1rem;
            text-align: right;
            position:absolute;
            left:0px;
            top:0px;
            bottom:0px;
            right:0px;
            &.sel{
                border:1px solid #3D6FFF;
                background: none;
            }
        }
        .match-product-dis-bot{
            background: rgba(255, 255, 255, 0.8);
            border-radius: 1rem;
            text-align: right;
            position:absolute;
            left:0px;
            top:0px;
            bottom:0px;
            right:0px;
            &.sel{
                border:1px solid #3D6FFF;
                background: none;
            }
            div{
              position: relative;
              width: 100%;
              height:100%;
              img{
                height:2.8rem;
                position:absolute;
                bottom:1rem;
                right:-1rem;
              }
            }
        }
    }
    .match-product-item{
        padding:1rem;
        background: #fff;
        border-radius: 1rem;
        

        .item-news{
            border: 30px solid #c90100;
            height: 0;
            width: 0;
            border-right-color: transparent;
            border-bottom-color: transparent;
            position: absolute;
            margin-left: -1rem;
            margin-top: -1rem;
            border-top-left-radius: 1rem;
            .item-news-title{
                margin-left: -1.3rem;
                margin-top: -1.3rem;
                color: #fff;
            }
        }

        .item-hr{
            height: 1px;
            background: #f3f3f3;
            margin-top:0.3rem;
            margin-bottom:0.3rem;
        }
        .item-label{
            margin-top:1rem;
            margin-left:2rem;
            margin-right:2rem;
            font-size:0.9rem;
            .item-label-div{
                margin-bottom:0.5rem;
                display:flex;
                img{
                    width:1.2rem;
                    height:1.2rem;
                }
                div{
                    margin-left:0.4rem;
                }
            }
        }
        .item-top{
            display:flex;
            .item-top-img{
                width:4rem;
                height:4rem;
            }
            .item-top-desc{
                margin-left:1rem;
                flex:1;
                .item-back{
                    margin-top:0.5rem;
                    font-size:0.9rem;
                    width:100%;
                    .item-back-desc{
                        display: inline-block;
                        color:#3D6FFF;
                        border:1px solid #3D6FFF;
                        padding-left:0.3rem;
                        padding-right:0.3rem;
                        margin-right:0.5rem;
                        border-radius:0.3rem;
                        margin-bottom: 0.3rem;
                    }
                }
                .item-prop{
                    color:#949999;
                    font-size:0.9rem;
                    width:100%;
                    .item-prop-desc{
                        display: inline-block;
                        margin-right:1rem;
                        margin-top:0.5rem;
                        .red{
                            margin-left:0.2rem;
                            color:#C90000;
                        }
                        .black{
                            margin-left:0.2rem;
                            color:#0A1E3C;
                        }
                    }
                }
                .item-name{
                    display:flex;
                    .item-name-name{
                        font-weight:500;
                    }
                    .item-name-desc{
                        margin-left:auto;
                        font-size:0.8rem;
                        margin-left:auto;
                        color:#3D6FFF;
                    }
                }
                .item-warn{
                    background: #fcefe5;
                    color: #d21616;
                    padding-left: 0.3rem;
                    padding-right: 0.3rem;
                    border-radius: 0.2rem;
                    display: inline;
                }
            }
        }
    }
}


.dtshop{
    margin-top:0.5rem;
    display:flex;
    margin-left:1rem;
    margin-right:1rem;
    color: #949999;
    .dt4{
        width:2rem;
    }
    .dt1{
        text-align:center;
        font-size:1rem;
    }
    .dt2{
        padding-top:0.3rem;
        padding-bottom:0.3rem;
        &.act{
            font-weight:bold;
            font-size:1.1rem;
            color:#3D6FFF;
        }
    }
    .dt3{
        &.act{
            height:0.2rem;
            background:#3D6FFF;
            margin-left:1rem;
            margin-right:1rem;
            border-radius:0.15rem;
        }
    }
}

.match-center-type{
    margin:1rem;
    .match-center-type-name{
        font-size:0.9rem;
    }
    .match-center-type-type1{
        overflow-x:auto;   
    }
    .match-center-type-type2{
        
        white-space:nowrap;
        font-size:0.9rem;
    
        .btn{
            display:inline-block;
            padding-left:1.3rem;
            padding-right:1.3rem;
            color:#9ca1a0;
            &.sel{
                background:#deeef7;
                color:#000;
                border-radius:1rem;
            }
        }
    }
    .match-center-type-type{
        display: flex;
        font-size:0.9rem;
        
        .ml{
            margin-left:0.5rem;
        }
    
        .btn{
            padding-left:1.3rem;
            padding-right:1.3rem;
            &.sel{
                background:#3D6FFF;
                color:#fff;
                border-radius:1rem;
            }
        }
        .change-div{
            display:flex;
            margin-left:auto;
            .change-img{
                margin-top:0.2rem;
                height:1rem;
            }
            .change-name{
                margin-left:0.2rem;
                color:#3D6FFF;
            }
        }
    }
}

.con-title{
    display: flex;
    margin:1rem;
    margin-top: 2rem;
    .con-title-bg-img{
        margin-top: -0.5rem;
        margin-left:1rem;
        flex: 1;
        .con-title-bg-img-img{
            margin-left: auto;
            text-align: right;
            img{
                width: 1.2rem;
            }
        }
        
        .con-title-bg{
            height:0.5rem;
            background: #e9ebeb;
            border-radius: 0.5rem;
            border-top-right-radius: 0;
        }
        
    }
    .con-title-detail{
        font-weight: 500;
    }
    .con-title-detail-sub{
        color: #949999;
        margin-top: 0.2rem;
        margin-left: 1rem;
        font-size: 0.8rem;
    }
    .con-title-desc{
        margin-left: auto;
        color:#3D6FFF;
        font-size:0.8rem;
    }
}
.dt{
    margin-top:0.5rem;
    display:flex;
    margin-left:1rem;
    margin-right:1rem;
    .dt4{
        flex:1;
        text-align: center;
        .sp{
            margin: auto;
            margin-top: 0.5rem;
            padding-top:0.3rem;
            padding-bottom:0.3rem;
            width:1px;
            height:0.28rem;
            background: #949999;
        }
    }
    .dt1{
        text-align:center;
        font-size:0.9rem;
    }
    .dt2{
        padding-top:0.3rem;
        padding-bottom:0.3rem;
        display: flex;
        .dt2-sj-b{
            margin-top: 0.5rem;
            margin-left: 0.5rem;
        }
        .dt2-sj{
            width: 0px;
            height: 0px;
            border: 0.33rem solid transparent;
            border-top-color: #949999;
        }
    }
    .dt3{
        &.act{
            height:0.2rem;
            background:#3D6FFF;
            margin-left:1rem;
            margin-right:1rem;
            border-radius:0.15rem;
        }
    }
}

.banner{
  width:100%;
  height:auto;
}

.search{
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
}

.divlight{
    position: absolute;
    display: flex;
    left: 1rem;
    right: 1rem;
    top: 1rem;
    .light{
        width: 3rem;
        height: 2.8rem;
    }
    .light_desc{
        color: #fff;
        margin-left: 1rem;
        .name{
            font-size: 1.2rem;
        }
        .desc{
            font-size: 0.8rem;
        }
    }
    .light_kf{
      margin-left:auto;
      margin-top:0.5rem;
      img{
        width: 7rem;
      }
    }
}

.app{
    .ml{
        margin-left:0.5rem;
    }
    .app-con{
        display: flex;
        margin-left:1rem;
        margin-right:1rem;
        
        .app-item{
            text-align: center;
            flex:1;
            border-radius: 1rem;
            height: 5rem;
            align-items:center;
            img{
                width:4rem;
                flex:1;
            }
            .app-title{
                text-align: center;
                font-size: 0.8rem;
            }
        }
    }
  
}

.service{
  .ml{
    margin-left:0.5rem;
  }
  

  .service-con{
    display: flex;
    margin-left:1rem;
    margin-right:1rem;
    
    .service-item{
     
      flex:1;
      border-radius: 1rem;
      display: flex;
      height: 5rem;
      align-items:center;
      img{
        margin-left:1rem;
        width: 2rem;
        height: 2rem;
      }
      .service-item-title{
        margin-left: 0.5rem;
        .title1{
          font-size: 0.9rem;
          font-weight: bold;
        }
        .title2{
          font-size: 0.6rem;
          color: grey;
        }
      }
      // background: url(@/assets/img/index/service_company.png);
    }
  }
  .service-title{
    text-align: center;
    color: #0071BC;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .service-title2{
    text-align: center;
    color: #848E9D;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 4rem;
  }

  .service-title3{
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}

.customer{
  .customer-con{
    margin:1rem;
    margin-left:2rem;
    margin-right:2rem;
    .customer-con-div{
      height:1.8rem;
      padding: 0.5rem;
      display: flex;
      justify-items: center;
      justify-content: center;
      line-height:1.8rem;
      color:#DCCCEC;
      
      .customer-con-div-name{
        display: flex;
        justify-content: center;
        justify-items: center;
        flex: 2;
        img{
          height: 1.4rem;
          margin-top:0.2rem;
        }
        div{
          vertical-align: middle;
          margin-left: 0.5rem;
        }
      }
      .customer-con-div-phone{
        justify-items: center;
        justify-content: center;
        display: flex;
        flex: 3;
      }
      .customer-con-div-time{
        text-align: center;
        vertical-align: middle;
        flex: 2;
      }

      &.on{
        color:#5000A0;
        background: #DCCCEC;
        border-radius: 1.3rem;
        .lineh{
          margin-top: 0.4rem;
          width:1px;
          height: 1rem;
          background: #5000A0;
        }
      }
    }
  }
  .customer-title-sub{
    text-align: center;
    color: #5000A0;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 2rem;
  }
  .customer-title{
    margin-top:2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .customer-label{
      color: #5000A0;
      line-height: 2rem;
      align-items: center;
      font-weight: 500;
      margin-left: 1rem;
      font-size: 1.1rem;
      a{
        // background: blue;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        color: #fff;
        border-radius: 0.3rem;
        font-size:1.3rem;
        background: -webkit-linear-gradient(left top, #5000A0 , #0071BC); /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(bottom right, #5000A0, #0071BC); /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(bottom right, #5000A0, #0071BC); /* Firefox 3.6 - 15 */
        background: linear-gradient(to bottom right, #5000A0 , #0071BC); /* 标准的语法 */
      }
    }
  }
}

.fa-title{
  margin:auto;
  text-align: center;
  font-size: 1.2rem;
  color: #225fb1;
}
.fa{
  margin: 1.2rem;
  .fa-btn{
    
  }
  .mt{
    margin-top:0.5rem;
  }
  .fa-div{
    background: #fff;
    border: 1px solid #949999;
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items:center;
    .fa-div-label{
      margin:0.8rem;
      color:#afb4bd;
    }
    img{
      width: 1rem;
      height: 1.3rem;
      vertical-align: middle;
    }
  }

  .fa-div-yzm{
    display: flex;
  }
  .fa-chk{
    img{
      width:1rem;
    }
  }

  .fa-div-radio{
    margin-top:1rem;
    padding-left:1rem;
    display: flex;
    align-items: center;
    .fa-radio{
      width:0.8rem;
      height:0.8rem;
      border: 1px solid #ddd;
      border-radius: 50%;
      .fa-sel{
        width:0.6rem;
        height:0.6rem;
        margin:0.1rem;
        border-radius: 50%;
        background: #4372f6;
      }
    }
    .fa-label{
      font-size: 0.9rem;
      margin-left: 0.5rem;
      color:#0A1E3C;
    }
    .fa-label-xy{
      font-size: 0.7rem;
      margin-left: 0.5rem;
      color:#afb4bd;
      a{
        color:#3D6FFF;
      }
    }

     
  }

  .hr{
    background: #ddd;
    height:1px;
  }

  .van-field__control{
    margin-left: 0.5rem;
  }
}
</style>
