<template>
<div class="share-day">
    <van-row gutter="20">
        <van-col span="8" v-for="item in data.pickList" :key="item.pick_code">
            <img @click="clickImg(item)" :src="item.pick_img_org"/>
        </van-col>
        <!-- <van-col span="8"><img src="@/assets/img/home/share.png"/></van-col>
        <van-col span="8"><img src="@/assets/img/home/share.png"/></van-col>
        <van-col span="8"><img src="@/assets/img/home/share.png"/></van-col>
        <van-col span="8"></van-col>
        <van-col span="8"></van-col> -->
    </van-row>
</div>
</template>

<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose,defineEmits} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../../common/http.js';
import {isNull,showMessage} from '../../common/utils.js'

let data=reactive({
    pickList:[]
})

const props=defineProps({
    subType:""
})

const emit=defineEmits(['clickShare']);

function clickImg(item){
  emit('clickShare',{type:"day",item:item});
}

let search=reactive({
    "pickType":"60"
})

search.subType=props.subType;

function searchPick(){
  api.post("/web/pick/picklist",search).then((res)=>{
        if(res.errcode=="0"){
          data.pickList=res.rows;
          if(data.pickList){
              var count=3-data.pickList.length%3;
              if(count!=0){
                  for(var i=0;i<count;i++){
                    data.pickList.push({})
                  }
              }
          }
        }else{
          showMessage(res.errmsg);
        }
  })
}

function searchByParams(subType){
  search.subType=subType;
  searchPick();
}

defineExpose({
  searchByParams
})

searchPick();
</script>

<style scoped lang="scss">
.share-day{
    margin:1rem;
    padding:1rem;
    img{
        width:100%;
    }
}
</style>